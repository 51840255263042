import type { PreviewRendererProps } from '../types';
import { useLoadCardData } from '../useLoadCardData';
import { ErrorIndicator, LoadingIndicator } from '../components';

export const ChildComponentRenderer = ({ loader }: PreviewRendererProps<'childComponent'>) => {
    const { data, isLoading, hasError, errorMessage } = useLoadCardData(loader);

    if (isLoading) {
        return <LoadingIndicator label="Loading..." />;
    }

    if (hasError) {
        return <ErrorIndicator message={errorMessage} />;
    }

    if (data?.childComponent) {
        const ChildComponent = data.childComponent;
        return <ChildComponent />;
    }

    return null;
};
