import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

export const uploadImageAndGetImageUrl = async (
    img: Blob,
    signal?: AbortSignal,
    deleteAfterDays: number = 1,
): Promise<string> => {
    const uploadApi = 'https://uploads.documents.cimpress.io/v1/uploads';
    const formData = new FormData();
    formData.append('file', img);

    const queryParams = new URLSearchParams({
        deleteAfterDays: deleteAfterDays.toString(),
    }).toString();

    try {
        const result = await axios.post<[{ uploadId: string }]>(`${uploadApi}?${queryParams}`, formData, {
            headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'multipart/form-data' },
            signal,
        });
        const imageId = result.data[0].uploadId;

        return `https://uploads.documents.cimpress.io/v1/uploads/${imageId}?tenant=default`;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.error(`An error occurred while uploading to "${uploadApi}": ${error}`);
        throw error;
    }
};
