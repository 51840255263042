import type { BoundingBox, GroupLayout, LayoutElement } from '@rendering/plasma';

// This code is copied from fusion's demo app although it has been modified to fit the needs of this project.
// https://gitlab.com/Cimpress-Technology/DocDesign/Rendering/fusion/-/blob/main/apps/demo/src/utils/drawMeasurementData.ts?ref_type=heads

const drawRect = ({
    boundingBox,
    context,
    scalar,
    color,
}: {
    boundingBox: BoundingBox;
    context: CanvasRenderingContext2D;
    scalar: number;
    color: string;
}): void => {
    context.save();
    const x = boundingBox.left * scalar;
    const y = boundingBox.top * scalar;
    const width = boundingBox.width * scalar;
    const height = boundingBox.height * scalar;
    context.strokeStyle = color;
    context.lineWidth = 1;
    context.rect(x, y, width, height);
    context.stroke();
    context.restore();
};

export const drawPreviewBoxes = ({
    scalar,
    layoutElements,
    context,
    color,
}: {
    scalar: number;
    layoutElements: LayoutElement[];
    context: CanvasRenderingContext2D;
    color: string;
}): void => {
    layoutElements.forEach((element) => {
        if (element.renderingOperation.type === 'group') {
            element.renderingOperation.contents.forEach((content) => {
                const transform = (element.renderingOperation as GroupLayout).transform;
                context.transform(
                    transform.a,
                    transform.b,
                    transform.c,
                    transform.d,
                    transform.x * scalar,
                    transform.y * scalar,
                );
                drawRect({ boundingBox: content.measurementData.previewBox, context, scalar, color });
                context.resetTransform();
            });
        } else {
            drawRect({
                boundingBox: element.measurementData.previewBox,
                context,
                scalar,
                color,
            });
        }
    });
};

export const drawBoundingBoxes = ({
    scalar,
    layoutElements,
    context,
    color,
}: {
    scalar: number;
    layoutElements: LayoutElement[];
    context: CanvasRenderingContext2D;
    color: string;
}): void => {
    layoutElements.forEach((element) => {
        if (element.renderingOperation.type === 'group') {
            element.renderingOperation.contents.forEach((content) => {
                const transform = (element.renderingOperation as GroupLayout).transform;
                context.transform(
                    transform.a,
                    transform.b,
                    transform.c,
                    transform.d,
                    transform.x * scalar,
                    transform.y * scalar,
                );
                drawRect({ boundingBox: content.measurementData.boundingBox, context, scalar, color });
                context.resetTransform();
            });
        } else {
            drawRect({
                boundingBox: element.measurementData.boundingBox,
                context,
                scalar,
                color,
            });
        }
    });
};
