import { Card } from '@cimpress/react-components';
import {
    DocumentPreviewRenderer,
    DocumentUrlPreviewRenderer,
    ImageUrlPreviewRenderer,
    ChildComponentRenderer,
} from './renderers';
import type { PreviewCardProps, PreviewType } from './types';

import classes from './PreviewCard.module.css';

const RENDERERS: Record<PreviewType, any> = {
    document: DocumentPreviewRenderer,
    documentUrl: DocumentUrlPreviewRenderer,
    imageUrl: ImageUrlPreviewRenderer,
    childComponent: ChildComponentRenderer,
};

export const PreviewCard = <T extends PreviewType>({ title, type, loader, size = 'small' }: PreviewCardProps<T>) => {
    const PreviewRenderer = RENDERERS[type];

    if (!PreviewRenderer) {
        return <div>Unsupported card type</div>;
    }

    return (
        <Card className={size === 'small' ? classes.card : classes[`card-${size}`]} isMinimal header={title}>
            <PreviewRenderer loader={loader} />
        </Card>
    );
};
