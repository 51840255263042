import type { CimpressDocument } from '@mcp-artwork/cimdoc-types-v2';
import axios from 'axios';
import base64js from 'base64-js';
import pako from 'pako';
import { v4 as uuidv4 } from 'uuid';
import config from '../config';
import { getUrl, REQUESTER } from '../tools';
import type { GenerateAssets } from './composeGenerateApi';
import type { ComposeAssets } from './compositeApi';
import type { InspirationGenerateOptions } from './inspirationGenerateApi';
import { uploadJsonDocumentAndGetDocumentUrl } from './uploadJsonDocumentAndGetDocumentUrl';

const host = config.backendServiceUrl;

export const getResizeByDimensionsUrl = (
    documentUrl: string | null,
    width: string,
    height: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
): string | null => {
    if (!documentUrl) {
        return null;
    }

    const basePath = `/api/${version}/adaptation:resize`;

    const params: Record<string, string | undefined> = {
        width,
        height,
        documentUrl,
        requester: REQUESTER,
        apiKey,
        minimumFontSize,
        priority,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getTransferCustomizationUrl = (
    sourceUrl: string | null,
    targetUrl: string | null,
    transferCustomerAssets: boolean,
    apiKey: string,
    useSmartTextTransfer = false,
    version = 'v3',
): string | null => {
    if (!sourceUrl || !targetUrl) {
        return null;
    }

    const basePath = `/api/${version}/preservation:transferCustomizations`;

    const params = {
        sourceDocumentUrl: getUrl(sourceUrl) ?? '',
        targetDocumentUrl: getUrl(targetUrl) ?? '',
        transferCustomerAssets: transferCustomerAssets?.toString(),
        useSmartTextTransfer: useSmartTextTransfer?.toString(),
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getAdjustForSubstrateUrl = (
    sourceUrl: string | null,
    targetSubstrateColor: string,
    minimumContrastRatio: string | number,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetSubstrateColor) {
        return null;
    }

    const basePath = '/api/v3/adaptation:adjustForSubstrate';

    const params = {
        targetSubstrateColor,
        documentUrl: getUrl(sourceUrl) ?? '',
        minimumContrastRatio: minimumContrastRatio.toString(),
        requester: REQUESTER,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getAdjustForSurfaceUrl = (
    source: string | null,
    surfaceSpecificationUrl: string,
    targetSubstrateColor: string,
    apiKey: string,
): string | null => {
    if (!source || !surfaceSpecificationUrl) {
        return null;
    }

    const basePath = '/api/v3/adaptation:adjustForSurface';

    const params: Record<string, string | undefined> = {
        documentUrl: getUrl(source) ?? '',
        surfaceSpecificationUrl,
        targetSubstrateColor: targetSubstrateColor || undefined,
        requester: REQUESTER,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getAdjustForDecorationTechnologyUrl = (
    sourceUrl: string | null,
    targetDecorationTechnology: string,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetDecorationTechnology) {
        return null;
    }

    const basePath = '/api/v3/adaptation:adjustForDecorationTechnology';

    const params: Record<string, string | undefined> = {
        documentUrl: getUrl(sourceUrl) ?? '',
        targetDecorationTechnology,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getImproveArtworkContrastUrl = (
    sourceUrl: string | null,
    minimumContrastRatio: number | string | null,
    apiKey: string,
): string | null => {
    if (!sourceUrl) {
        return null;
    }

    const basePath = '/api/v3/quality:improveContrast';

    const params: Record<string, string | undefined> = {
        documentUrl: getUrl(sourceUrl) ?? '',
        minimumContrastRatio: minimumContrastRatio?.toString(),
        requester: REQUESTER,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export const getTransferStyleUrl = (
    sourceUrl: string | null,
    targetUrl: string | null,
    apiKey: string,
): string | null => {
    if (!sourceUrl || !targetUrl) {
        return null;
    }

    const basePath = '/api/v3/preservation:transferStyle';

    const params: Record<string, string | undefined> = {
        sourceDocumentUrl: getUrl(sourceUrl) ?? '',
        targetDocumentUrl: getUrl(targetUrl) ?? '',
        requester: REQUESTER,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

export function getResizeDocumentWithSurfaceUrl(
    documentUrl: string,
    surfaceSpecificationUrl: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
    sourceSurfaceSpecificationUrl?: string,
): string {
    const basePath = `/api/${version}/adaptation:resize`;

    const params: Record<string, string | undefined> = {
        documentUrl,
        surfaceSpecificationUrl,
        requester: REQUESTER,
        apiKey,
        minimumFontSize,
        priority,
        sourceSurfaceSpecificationUrl,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
}

export function getResizeDocumentWithSurfaceJson(
    documentUrl: string,
    surfaceSpecification: string,
    minimumFontSize: string | undefined,
    apiKey: string,
    version: string = 'v3',
    priority: string = 'speed',
    sourceSurfaceSpecification?: string,
): string {
    const basePath = `/api/${version}/adaptation:resize`;

    const params: Record<string, string | undefined> = {
        documentUrl,
        surfaceSpecification: JSON.stringify(surfaceSpecification),
        requester: REQUESTER,
        apiKey,
        minimumFontSize,
        priority,
        sourceSurfaceSpecification,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
}

export const getComposeUrl = ({
    documentUrl,
    assets,
    apiKey,
    priority = 'speed',
    version = 'v3',
    hostUrl = host,
    endpoint,
}: {
    documentUrl: string | null;
    assets: ComposeAssets | null;
    apiKey: string;
    priority: string;
    version?: string;
    hostUrl?: string;
    endpoint?: string;
}): string | null => {
    if (!documentUrl) {
        return null;
    }

    const basePath = endpoint || `/api/${version}/composition:compose`;

    const params: Record<string, string | undefined> = {
        documentUrl: getUrl(documentUrl) ?? '',
        assets: assets ? JSON.stringify(assets) : undefined,
        requester: REQUESTER,
        apiKey,
        priority,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, hostUrl);
    return url.toString();
};

// TODO: This is temporary function added for QA of the new compose experimental endpoint
export const getComposeExperimentalUrl = ({
    documentUrl,
    assets,
    apiKey,
    priority = 'speed',
    flow = 'NoAi',
}: {
    documentUrl: string | null;
    assets: ComposeAssets | null;
    apiKey: string;
    priority: string;
    flow?: string;
}): string | null => {
    if (!documentUrl) {
        return null;
    }

    const basePath = `/api/v0/experiment:compose-logo`;

    const params: Record<string, string | undefined> = {
        documentUrl: getUrl(documentUrl) ?? '',
        assets: assets ? JSON.stringify(assets) : undefined,
        requester: REQUESTER,
        apiKey,
        priority,
        flow,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);
    return url.toString();
};

export const getCompositionGenerateUrl = ({
    assets,
    surfaceSpecificationUrl,
    surfaceSpecification,
    sourceDocumentUrl,
    productName,
    panels,
    dpiThreshold,
    apiKey,
    culture = 'en-us',
    apiVersion = 'v3',
    layoutSpec,
}: {
    assets?: GenerateAssets;
    surfaceSpecificationUrl?: string;
    surfaceSpecification?: any;
    sourceDocumentUrl?: string;
    productName?: string;
    panels?: string;
    dpiThreshold?: number;
    apiKey: string;
    culture?: string;
    apiVersion?: string;
    layoutSpec?: string;
}): string => {
    const basePath = `/api/${apiVersion}/composition:generate`;

    const params: Record<string, string | undefined> = {
        surfaceSpecificationUrl,
        surfaceSpecification: surfaceSpecification ? JSON.stringify(surfaceSpecification) : undefined,
        productName,
        culture,
        panels,
        dpiThreshold: dpiThreshold?.toString(),
        apiKey,
        waitTime: '30',
        sourceDocumentUrl,
        assets: assets ? JSON.stringify(assets) : undefined,
        layoutSpec,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);
    return url.toString();
};
export const getInspirationGenerateFromImageUrls = async ({
    themeQuery,
    imageAsset,
    iconCollection,
    texts,
    substrateColor,
    surfaceSpecificationUrl,
    surfaceSpecification,
    useImage,
    colorPalette,
    textAssetCategory,
    contentAlignment,
    signal,
    apiKey,
}: {
    themeQuery: string | null;
    imageAsset: any | null;
    iconCollection: string | null;
    texts: any | null;
    surfaceSpecificationUrl: string | null;
    surfaceSpecification: any | null;
    substrateColor: string | null;
    useImage: boolean | null;
    colorPalette: any | null;
    textAssetCategory: any | null;
    contentAlignment?: string;
    signal?: AbortSignal;
    apiKey: string;
}): Promise<string[]> => {
    const url = new URL(`${host}/api/v3/experiment:inspiration:generate`);
    if (surfaceSpecificationUrl) url.searchParams.append('surfaceSpecificationUrl', surfaceSpecificationUrl);
    if (surfaceSpecification) url.searchParams.append('surfaceSpecification', JSON.stringify(surfaceSpecification));
    url.searchParams.append('apiKey', apiKey);
    url.searchParams.append('nonce', uuidv4());

    if (texts) url.searchParams.append('texts', JSON.stringify(texts));
    if (substrateColor) url.searchParams.append('substrateColor', substrateColor);
    if (useImage != null) url.searchParams.append('useImage', `${useImage}`);
    if (colorPalette) url.searchParams.append('colorPalette', JSON.stringify(colorPalette));
    if (textAssetCategory) url.searchParams.append('textAssetCategory', textAssetCategory);
    if (themeQuery) url.searchParams.append('themeQuery', themeQuery);
    if (imageAsset) url.searchParams.append('imageAsset', imageAsset);
    if (iconCollection || contentAlignment) {
        const generateOptionsObj: InspirationGenerateOptions = {};
        if (iconCollection) generateOptionsObj.iconCollections = [iconCollection];
        if (contentAlignment) generateOptionsObj.contentAlignment = contentAlignment;
        url.searchParams.append('generateOptions', JSON.stringify(generateOptionsObj));
    }

    const response = await axios.get(url.href, { signal });

    const resultDocuemtUrls = await Promise.all(
        response.data.map(async (document: any) => {
            try {
                const url = getTransientDocumentUrl(document);
                return url;
            } catch (error) {
                document.deleteAfterDays = 1;
                return await uploadJsonDocumentAndGetDocumentUrl(document, signal);
            }
        }),
    );
    return resultDocuemtUrls;
};

function getTransientDocumentUrl(cimdoc: CimpressDocument) {
    const MAX_DATA_URI_LENGTH = 7000;

    const deflatedDocument = pako.deflateRaw(JSON.stringify(cimdoc), {
        to: 'string',
    } as any); // For some reason we're passing an object that doesn't match the types

    const transientDocument = base64js.fromByteArray(deflatedDocument);

    if (transientDocument.length > MAX_DATA_URI_LENGTH) {
        throw new Error(`Provided cimdoc exceeds inline document url length of ${MAX_DATA_URI_LENGTH}`);
    }

    const basePath = '/v3/documents/transient';
    const params = {
        document: transientDocument,
    };
    const url = createUrlWithParamsAndNonce(basePath, params, 'https://storage.documents.cimpress.io');

    return url.toString();
}

export const getRenderingUrlDocument = (documentUrl: string, panel: number, backgroundColor?: string): string => {
    const basePath = '/v3/instructions:preview';

    const params: Record<string, string | undefined> = {
        documentUri: documentUrl,
        surfaceOrdinals: `${panel + 1}`,
        ignoreProjection: 'True',
    };

    const instructionsUri = createUrlWithParamsAndNonce(
        basePath,
        params,
        'https://instructions.documents.cimpress.io',
    ).toString();

    let renderingUrl = `https://rendering.documents.cimpress.io/v2/orchestration/preview?width=700&height=700&instructions_uri=${encodeURIComponent(instructionsUri)}&format=png`;

    if (backgroundColor) {
        renderingUrl += `&bgColor=${backgroundColor}`;
    }

    return renderingUrl;
};

export const getRenderingUrl = (
    documentUrl: string,
    panel: number,
    surfaceSpecUrl: string,
    projection: string,
    backgroundColor: string | undefined,
): string => {
    const isSurfaceUrlDSS = surfaceSpecUrl.includes('design-specifications');

    const sceneUriPart = isSurfaceUrlDSS ? '' : createSceneUriPart(panel, projection, surfaceSpecUrl);
    const instructionsUriPart = createInstructionUriPart(documentUrl);

    let renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?${instructionsUriPart}&showerr=1&scene=${sceneUriPart}&width=1360&format=png`;
    if (backgroundColor) {
        renderingUrl += `&bgColor=${backgroundColor}`;
    }
    return renderingUrl;
};

export const getRenderingUrlWithPhotoScene = (documentUrl: string, scene: string | null): string => {
    const instructionsUriPart = createInstructionUriPart(documentUrl);
    const renderingUrl = `https://rendering.documents.cimpress.io/v1/cse/preview?${instructionsUriPart}&showerr=1&scene=${scene}&width=1360&format=png`;

    return renderingUrl;
};

function createInstructionUriPart(documentUrl: string): string {
    const docInstructionsUrl = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodeURIComponent(
        documentUrl,
    )}&salt=${Math.random()}`;

    const instructionsUriPart = docInstructionsUrl ? `instructions_uri=${encodeURIComponent(docInstructionsUrl)}` : '';

    return instructionsUriPart;
}

function createSceneUriPart(panel: number, projection: string, surfaceSpecUrl: string): string {
    const sceneJson = {
        width: 1000,
        page: panel + 1,
        projection: projection,
        // showFullBleed: true,
        product: {
            calculatedSurfaceSetUrl: surfaceSpecUrl,
        },
        layers: [
            {
                type: 'overlay',
                source: 'safe',
                stroke: {
                    color: 'rgb(0,0,0)',
                    dotted: true,
                },
            },
        ],
    };

    // Deflate the scene
    const deflatedScene = pako.deflateRaw(JSON.stringify(sceneJson), {
        to: 'string',
    } as any); // For some reason we're passing an object that doesn't match the types

    const transientScene = base64js.fromByteArray(deflatedScene);

    // Construct the scene Url
    const sceneUrl = `https://scenes.documents.cimpress.io/v3/transient?data=${encodeURIComponent(transientScene)}`;
    const sceneUriPart = `${encodeURIComponent(sceneUrl)}`;

    return sceneUriPart;
}

export function isUrlAllowed(url: string, allowedDomains = ['artworkgeneration.cimpress.io']): boolean {
    try {
        const parsedUrl = new URL(url);
        return allowedDomains.some((domain) => parsedUrl.hostname.includes(domain));
    } catch (error) {
        return false;
    }
}

export const getImageToCimdocUrl = (imageUrl: string, apiKey: string): string | null => {
    if (!imageUrl) {
        return null;
    }

    const basePath = `/api/v0/adaptation:convertImageToCimDoc`;

    const params: Record<string, string> = {
        imageUrl,
        apiKey,
    };

    const url = createUrlWithParamsAndNonce(basePath, params, host);

    return url.toString();
};

const createUrlWithParamsAndNonce = (
    basePath: string,
    params: Record<string, string | undefined>,
    host: string,
): URL => {
    const url = new URL(basePath, host);

    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined) {
            url.searchParams.set(key, String(value));
        }
    });

    url.searchParams.set('nonce', uuidv4());

    return url;
};
